<template>
    <div class="comparison-container">

        <div class="row row-lg-flex chart-row">
            <section class="col-lg-6 col-md-12 col-lg-flex" v-if="compareChartIsActive">
                <card class="tour-step-1"
                      icon="chart-area"
                      :headline="$t('visitors.compare.headline')"
                      :description="$t('visitors.periodic.description')">

                    <div slot="header" v-if="compareChartIsActive">
                                <span @click="selectedCompareMethod = compareMethod.slug"
                                      v-for="(compareMethod, index) in compareMethods"
                                      v-tooltip="$t(`visitors.compare.${compareMethod.slug}`)"
                                      :key="index"
                                      class="compare-method-selector"
                                      :class="{ 'active': selectedCompareMethod === compareMethod.slug }"
                                      style="margin-left: 5px; cursor: pointer;">
                                    <i class="fa" :class="compareMethod.icon"/>
                                </span>
                    </div>

                    <visitors-line-chart @hasLoaded="startTourWhenReady()"
                                         @visibility-change="setVisible"
                                         :visible="visible"
                                         :from="lastPeriodFrom"
                                         :sort="interval"
                                         :to="lastPeriodTo"
                                         @totalAxisCalculated="setChartYAxis"
                                         @valuesFetched="setChartAValues"
                                         :yAxisMaxValue="compareChartIsActive ? yAxisMaxValue : null"
                                         :id="0"/>
                </card>
            </section>
            <section class="col-md-12 col-lg-flex" :class="[compareChartIsActive ? 'col-lg-6' : 'col-lg-8']">
                <card class="tour-step-1"
                      icon="chart-area"
                      :headline="$t('visitors.periodic.headline')"
                      :description="$t('visitors.periodic.description')">

                    <div slot="header">
                        <compare slot="header" @toggled="setComparisonMode"/>
                        <interval/>
                        <div class="clearfix"></div>
                    </div>

                    <visitors-line-chart @hasLoaded="startTourWhenReady()"
                                         :from="from"
                                         :to="to"
                                         :sort="interval"
                                         :visible="visible"
                                         @visibility-change="setVisible"
                                         @totalAxisCalculated="setChartYAxis"
                                         @valuesFetched="setChartBValues"
                                         :comparisonData="chartAValues"
                                         :yAxisMaxValue="compareChartIsActive ? yAxisMaxValue : null"
                                         :modeToggleChange="compareChartIsActive"
                                         :id="1"/>
                </card>
            </section>
            <aside class="col-lg-4 col-md-12 col-lg-flex" v-if="!compareChartIsActive">
                <pie-chart-sources :is-loading="isLoading" :visible="visible" @visibility-change="setVisible"/>
            </aside>
        </div>

        <div class="row" v-if="compareChartIsActive">
            <div class="col-md-12">
                <card>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>{{$t('visitors.source')}}</th>
                            <th>{{$t('visitors.periodA')}}</th>
                            <th>{{$t('visitors.periodB')}}</th>
                            <th>{{$t('visitors.changeInPercentage')}}</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="source in sources" :key="source.slug">
                            <td>{{translate(source.slug)}}</td>
                            <td>
                                <template v-if="sum(chartAValues, source.slug) !== null">
                                    {{sum(chartAValues, source.slug) | numberFormat}}
                                </template>

                                <span class="text-muted" v-else>-</span>
                            </td>

                            <td>
                                <template v-if="sum(chartBValues, source.slug) !== null">
                                    {{sum(chartBValues, source.slug) | numberFormat}}
                                </template>

                                <span class="text-muted" v-else>-</span>
                            </td>

                            <td>
                                <template v-if="change(source.slug) !== null">
                                                <span class="text-success" v-if="change(source.slug) > 1">
                                                    <span class="fa fa-caret-up fa-fw"/>
                                                    {{Math.abs(100 - change(source.slug) * 100).toFixed(1)}}%
                                                </span>

                                    <span class="text-danger" v-else-if="change(source.slug) < 1">
                                                    <span class="fa fa-caret-down fa-fw"/>
                                                    {{Math.abs(100 - change(source.slug) * 100).toFixed(1)}}%
                                                </span>

                                    <span class="text-muted" v-else>-</span>
                                </template>

                                <span class="text-muted" v-else>-</span>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Total</strong></td>
                            <td>
                                <strong>{{total(chartAValues) | numberFormat}}</strong>
                            </td>

                            <td>
                                <strong>{{total(chartBValues) | numberFormat}}</strong>
                            </td>

                            <td>
                                <strong>
                                    <template v-if="changeTotal() !== null">
                                                <span class="text-success" v-if="changeTotal() > 1">
                                                    <span class="fa fa-caret-up fa-fw"/>
                                                    {{Math.abs(100 - changeTotal() * 100).toFixed(1)}}%
                                                </span>

                                        <span class="text-danger" v-else-if="changeTotal() < 1">
                                                    <span class="fa fa-caret-down fa-fw"/>
                                                    {{Math.abs(100 - changeTotal() * 100).toFixed(1)}}%
                                                </span>

                                        <span class="text-muted" v-else>-</span>
                                    </template>

                                    <span class="text-muted" v-else>-</span>
                                </strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
  import Compare from '@/app/website/components/Compare'
  import PieChartSources from '@/app/website/components/PieChartSources'
  import VisitorsLineChart from '@/app/website/components/LineChart'
  import Interval from '@/app/datepicker/components/Interval'

  import moment from 'moment'
  import trafficSourcesMixin from '@/app/traffic-sources/traffic-sources.mixin'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  export default {
    props: ['isLoading'],
    mixins: [datepickerMixin, trafficSourcesMixin],
    data () {
      return {
        visible: [],
        yAxisMaxValue: 0,
        firstChartYAxis: 0,
        secondChartYAxis: 0,
        chartAValues: null,
        chartBValues: null,
        compareChartIsActive: false,
        selectedCompareMethod: 'samePeriodLastYear',
        compareMethods: [
          {
            icon: 'fa-fast-backward',
            slug: 'samePeriodLastYear'
          },
          {
            icon: 'fa-backward',
            slug: 'samePeriodLastPeriod'
          }
        ]
      }
    },

    mounted () {
      this.load()
    },
    computed: {
      lastPeriodFrom () {
        switch (this.selectedCompareMethod) {
          case 'samePeriodLastYear':
            return moment(this.from).subtract(1, 'year')

          case 'samePeriodLastPeriod':
            // Days between the two original dates with no filters
            const from = moment(this.from).startOf(this.interval)
            const to = moment(this.to).endOf(this.interval)

            const periodsBetween = moment(to).diff(from, this.interval) + 1

            // New FROM based on difference of original FROM / TO, but subtracted from NEW TO
            return moment(from).subtract(periodsBetween, this.interval).startOf(this.interval)
        }
      },

      lastPeriodTo () {
        switch (this.selectedCompareMethod) {
          case 'samePeriodLastYear':
            return moment(this.to).subtract(1, 'year')
          case 'samePeriodLastPeriod':
            // Days between the two original dates with no filters
            const from = moment(this.from).startOf(this.interval)

            // The day before original FROM
            return moment(from).subtract(1, this.interval)
        }
      }
    },
    methods: {
      startTourWhenReady () {
        this.$emit('hasLoaded')
      },

      setVisible (visible) {
        this.visible = visible
      },

      setChartYAxis (payload) {
        payload.id === 0
          ? this.firstChartYAxis = payload.yAxisMax
          : this.secondChartYAxis = payload.yAxisMax

        this.yAxisMaxValue = Math.max(this.firstChartYAxis, this.secondChartYAxis)
      },

      setComparisonMode (isToggled) {
        this.compareChartIsActive = isToggled
      },

      setChartAValues (values) {
        this.chartAValues = values
      },

      setChartBValues (values) {
        this.chartBValues = values
      },

      sum (dataSet, slug) {
        if (!dataSet || !dataSet[slug]) {
          return null
        }

        return dataSet[slug].data.reduce((sum, value) => sum + value)
      },

      load () {
        const sources = {
          adwords: {
            label: $t('trafficSources.adwords'),
            slug: 'adwords',
            color: $org('colors.trafficSources.adwords'),
            selected: true
          },
          facebook: {
            label: $t('trafficSources.facebook'),
            slug: 'facebook',
            color: $org('colors.trafficSources.facebook'),
            selected: true
          },
          organic: {
            label: $t('trafficSources.organic'),
            slug: 'organic',
            color: $org('colors.trafficSources.organic'),
            selected: true
          },
          direct: {
            label: $t('trafficSources.direct'),
            slug: 'direct',
            color: $org('colors.trafficSources.direct'),
            selected: true
          },
          referral: {
            label: $t('trafficSources.referral'),
            slug: 'referral',
            color: $org('colors.trafficSources.referral'),
            selected: true
          },
          total: {
            label: $t('trafficSources.total'),
            slug: 'total',
            color: $org('colors.trafficSources.total'),
            selected: true
          }
        }

        this.visible = Object.values(sources).map(source => source.slug)

        eventHub.$emit('topbar.filterGroups.push', {
          title: $t('conversions.sources'),
          slug: 'sources',
          multiSelect: true,
          metrics: Object.values(sources).map(network => {
            return {
              label: network.label,
              slug: network.slug,
              color: network.color,
              selected: 1
            }
          })
        })

        eventHub.$on('topbar.filterGroups.sources.metricsChanged', payload => {
          this.visible = Object.keys(payload.metrics).filter(metricKey => payload.metrics[metricKey])
        })
      },

      total (dataSet) {
        if (!dataSet) {
          return
        }

        const lines = []

        for (let key in dataSet) {
          if (key === 'total') {
            continue
          }

          lines.push(dataSet[key])
        }

        const values = lines.map(set => set.data.reduce((sum, value) => sum + value))

        return values.reduce((acc, item) => acc + item, 0)
      },

      change (slug) {
        const valueA = this.sum(this.chartAValues, slug)
        const valueB = this.sum(this.chartBValues, slug)

        if (valueB === null || valueA === null) {
          return null
        }

        return valueB / valueA
      },

      changeTotal () {
        const valueA = this.total(this.chartAValues)
        const valueB = this.total(this.chartBValues)

        if (valueB === null || valueA === null) {
          return null
        }

        return valueB / valueA
      }
    },
    components: {
      Compare,
      PieChartSources,
      VisitorsLineChart,
      Interval
    }
  }
</script>
