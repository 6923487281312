<template>
    <card icon="chart-area"
            :headline="$t('visitors.channels.headline')"
            :description="$t('visitors.channels.description')" >
        <div class="element visitors-donut-chart">
            <logo-loader :height="330" v-if="!hasLoaded"/>
            <div v-show="hasLoaded" class="chart-wrapper">
                <div class="chart"></div>
            </div>
        </div>
    </card>
</template>

<script>
  import Range from '@/app/layout/components/Range'
  import * as highCharts from 'highcharts'
  import { mapGetters } from 'vuex'
  import trafficSourcesMixin from '@/app/traffic-sources/traffic-sources.mixin'

  export default {
    props: {
      visible: {
        type: Array,
        default () {
          return []
        }
      }
    },
    mixins: [trafficSourcesMixin],

    computed: {
      ...mapGetters('website', {
        sessions: 'getSources',
      }),

      hasLoaded () {
        return !!this.sessions
      }
    },

    data () {
      return {
        chart: null,
        total: 0,
        channels: [],
        colors: [],
        internalVisible: []
      }
    },

    mounted () {
      this.internalVisible = this.visible.length > 0 ? this.visible : this.sources.map(source => source.slug)
      if (this.sessions) this.set()
    },

    watch: {
      'sessions.rows' () {
        if (this.sessions) this.set()
        else this.reset()
      },

      visible () {
        if (this.internalVisible === this.visible) {
          return
        }

        this.internalVisible = this.visible
        this.updateVisibility()
      },
    },

    methods: {
      reset () {
        if (!this.hasLoaded) {
          return
        }

        this.channels = []

        if (this.chart) {
          this.chart.destroy()
        }
      },

      set () {
        this.reset()
        this.total = this.sessions.total
        const channels = this.sessions.rows

        for (let i = 0; i < channels.length; i++) {
          this.channels.push([
            channels[i].sourceMedium,
            channels[i].sessions
          ])
        }

        this.channels.sort((a, b) => {
          const labelA = a[0].toLowerCase()
          const labelB = b[0].toLowerCase()

          if (labelA < labelB) {
            return -1
          }

          if (labelA > labelB) {
            return 1
          }

          return 0
        })

        this.colors = []

        this.channels.forEach(channel => {
          const color = this.colorForMedium(channel[0])
          this.colors.push(color)
        })

        this.render()
      },

      render () {
        const el = this.$el.querySelector('.chart')
        const that = this

        this.chart = new highCharts.Chart(el, {
          credits: false,
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: 315
          },
          title: {
            text: this.total,
            style: {
              'fontFamily': '"Roboto","Helvetica Neue",sans-serif',
              'fontWeight': '300',
              'fontSize': '45px'
            },
            align: 'center',
            verticalAlign: 'middle',
            y: -5
          },
          subtitle: {
            text: $t('analytics.visits'),
            style: {
              'fontFamily': '"Roboto","Helvetica Neue",sans-serif',
              'fontWeight': '300',
              'fontSize': '20px'
            },
            align: 'center',
            verticalAlign: 'middle',
            y: 27
          },
          tooltip: {
            headerFormat: null,

            pointFormatter: function () {
              return `${that.translate(this.name)}: <b>${this.percentage.toFixed(1)}%</b>`
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            pie: {
              size: '80%',
              dataLabels: {
                enabled: false,
                distance: -50,
                style: {
                  fontWeight: 'bold',
                  color: 'white'
                }
              },
              startAngle: 0,
              endAngle: 360,
              center: ['50%', '45%'],
              point: {
                events: {
                  legendItemClick: function (e) {
                    that.setVisibility(e)
                  }
                }
              }
            },

            series: {
              showInLegend: true,
            }
          },
          series: [{
            colors: this.colors,
            type: 'pie',
            name: $t('analytics.visits'),
            innerSize: '80%',
            data: this.channels
          }]
        })

        this.updateVisibility()
      },

      updateVisibility () {
        if (!this.chart) {
          return
        }

        const chart = this.chart
        const data = chart.series[0].data

        for (let key in data) {
          let point = data[key]
          let pointName = point.name

          if (this.internalVisible.indexOf(pointName) === -1) {
            point.setVisible(false)
            continue
          }

          point.setVisible(true)
        }

        this.calculateTotal()
      },

      setVisibility (e) {
        const chart = this.chart

        if (!chart.series) {
          return
        }

        const data = chart.series[0].data

        let visible = []

        for (let key in data) {
          let point = data[key]
          let pointName = point.name

          if (!point.visible) {
            continue
          }

          visible.push(pointName)
        }

        !e.target.visible ?
          visible.push(e.target.name) :
          visible.splice(visible.indexOf(e.target.name), 1)

        this.internalVisible = visible

        this.$emit('visibility-change', this.internalVisible)

        this.calculateTotal()
      },

      calculateTotal () {
        const chart = this.chart
        const data = chart.series[0].data

        let total = 0

        for (let key in data) {
          let point = data[key]
          let pointName = point.name

          if (this.internalVisible.indexOf(pointName) === -1) {
            continue
          }

          total += parseInt(point.y)
        }

        const filter = this.$root.$options.filters

        if (!filter.numberFormat) {
          return
        }

        chart.setTitle({ text: filter.numberFormat(total).toString() })
      }
    },

    components: {
      Range
    }
  }
</script>

<style lang="scss" scoped>
    .chart {
        min-height: 330px;
    }
</style>
