<template>
	<div class="element line-chart-element">
		<logo-loader :height="300" v-if="!hasLoaded"/>

		<div class="no-data" v-if="hasLoaded && !hasDataWithinPeriod">
			<span><i class="fa fa-warning"/>{{ $t('visitors.compare.noData') }}</span>
		</div>

		<div class="chart-wrapper" v-show="hasLoaded && hasDataWithinPeriod">
			<div class="chart"></div>
		</div>
	</div>
</template>

<script>
import AnalyticsService from '@/services/_app/google-analytics/AnalyticsService'
import LogoLoader from '@/app/shared/components/LogoLoader'
import Range from '@/app/layout/components/Range'
import {mapGetters} from 'vuex'
import trafficSourcesMixin from '@/app/traffic-sources/traffic-sources.mixin'
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";

const HighCharts = require('highcharts')

const moment = require('moment')
const axios = require('axios')

export default {
	props: {
		from: Object,
		to: Object,

		id: {
			type: Number,
			required: true
		},

		comparisonData: {
			type: Object,
			required: false
		},

		yAxisMaxValue: {
			type: Number,
			required: false
		},

		modeToggleChange: {
			type: Boolean,
			required: false
		},

		sort: {
			type: String,
			default: 'day'
		},

		visible: {
			type: Array,
			required: true
		}
	},
	mixins: [trafficSourcesMixin],

	data() {
		return {
			hasLoaded: false,
			yAxisMax: null,
			chart: null,
			labels: [],
			series: {},
			lineWidth: 1,
			hasDataWithinPeriod: false,
			averageVisits: 0,
			marker: {
				symbol: 'circle',
				radius: 3,
				lineWidth: 0,
				lineColor: '#fff'
			},
			cancel: null,
			allLineChartPoints: []
		}
	},

	computed: {
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),

		unfinished() {
			const to = moment(this.to)
			const comparison = moment()

			switch (this.sort) {
				case 'day':
					return to.format('DDMMYYYY') === comparison.format('DDMMYYYY')

				case 'week':
					const lastDayOfWeek = moment().endOf('isoWeek')

					return to.format('WYYYY') === comparison.format('WYYYY') &&
						to.format('DD') !== lastDayOfWeek.format('DD')

				case 'month':
					const lastDayOfMonth = moment().endOf('month')

					return to.format('MMYYYY') === comparison.format('MMYYYY') &&
						to.format('DD') !== lastDayOfMonth.format('DD')

				case 'year':
					const lastDayOfYear = moment().endOf('month')

					return to.format('YYYY') === comparison.format('YYYY') &&
						to.format('DD') !== lastDayOfYear.format('DD')

				default:
					return false
			}
		}
	},

	watch: {
		from() {
			this.reload()
		},

		sort() {
			this.reload()
		},

		visible() {
			this.updateVisibility()
		},

		yAxisMaxValue() {
			this.render()
		},

		modeToggleChange() {
			this.render()
		},

		series() {
			this.render()
		},

		hasLoaded() {
			if (!this.hasLoaded) {
				return
			}

			this.$emit('hasLoaded')
		}
	},

	mounted() {
		this.load()
		eventHub.$on('compare.analytics.visitors', this.mouseOverChart)
		eventHub.$on('compare.analytics.mouseOut', this.mouseOut)
	},

	destroyed() {
		this.cancelRequest()
		eventHub.$off('compare.analytics.visitors', this.mouseOverChart)
		eventHub.$off('compare.analytics.mouseOut', this.mouseOut)
	},

	methods: {
		cancelRequest() {
			if (!this.cancel) {
				return
			}

			this.cancel.cancel()
			this.cancel = null
		},

		reload() {
			if (this.hasLoaded) {
				this.hasLoaded = false

				this.cancelRequest()

				if (this.chart) {
					this.chart.destroy()
					this.chart = null
				}

				this.load()
			}
		},

		async load() {
			const cancelToken = axios.CancelToken
			const source = cancelToken.source()

			this.cancel = source

			const sort = this.sort

			this.series = {}
			this.labels = []

			this.$emit('valuesFetched', {})

			let from = moment(this.from).startOf(this.sort)
			let to = moment(this.to).endOf(this.sort)

			this.hasLoaded = false
			this.hasDataWithinPeriod = false

			let body = await AnalyticsService.sessionsWithSources(from, to, sort, {cancelToken: source.token});
			if (body?.success === false) {
				this.$swal({
					type: 'error',
					text: getTranslatedErrorMessage(body.errorCode)
				});
				body = body?.errorMsg;
			}
			const rows = body.rows

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i]
				this.addLabel(row)
			}

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i]
				const source = row.sourceMedium

				if (!this.series[source]) {
					this.createSerie(source)
				}
			}

			let tick = null
			let ticks = 1

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i]
				const source = row.sourceMedium

				let currentTick = row.year

				if (row.month) {
					currentTick = `${currentTick}-${row.month}`
				}

				if (row.day) {
					currentTick = `${currentTick}-${row.day}`
				}

				if (row.isoWeek) {
					currentTick = `${currentTick}-${row.isoWeek}`
				}

				if (row.week) {
					currentTick = `${currentTick}-${row.week}`
				}

				if (tick !== currentTick) {
					tick = currentTick

					if (ticks !== 1) {
						this.fillBlanks(ticks)
					}

					ticks++
				}

				this.series[source].data.push(row.sessions)
			}

			this.hasLoaded = true
			this.$emit('valuesFetched', this.series)

			if (!rows.length) {
				this.hasDataWithinPeriod = false
				return
			}

			this.hasDataWithinPeriod = true
			this.cancel = null

			this.fillBlanks(ticks)

			this.calculateTotal()
			this.render()
		},

		fillBlanks(rounds) {
			for (let key in this.series) {
				const serie = this.series[key]
				const pointsToAdd = rounds - serie.data.length - 1

				if (pointsToAdd <= 0) {
					continue
				}

				for (let i = 0; i < pointsToAdd; i++) {
					this.series[key].data.push(0)
				}
			}
		},

		addLabel(row) {
			let label, date

			switch (this.sort) {
				case 'day':
					date = moment(row.year + '-' + row.month + '-' + row.day, 'YYYY-MM-DD')
					label = date.format($t('i18n.dateFormat'))
					break

				case 'week':
					if (!! row.isoWeek) {
						label = $t('date.week') + ' ' + row.isoWeek + ' \'' + row.isoYear.toString().substr(2)
					} else {
						label = $t('date.week') + ' ' + row.week + ' \'' + row.year.toString().substr(2)
					}
					break

				case 'month':
					date = moment(row.year + '-' + row.month, 'YYYY-MM')

					label = date.format('MMMM \'YY')
					label = this.$options.filters.capitalize(label)
					break

				case 'year':
					label = row.year
					break
			}

			if (this.labels.indexOf(label) !== -1) {
				return
			}

			this.labels.push(label)
		},

		calculateTotal() {
			const dataRows = []

			Object.keys(this.series).map(key => {
				const data = this.series[key].data

				for (let i = 0; i < data.length; i++) {
					if (!dataRows[i]) {
						dataRows[i] = 0
					}

					dataRows[i] += data[i]
				}
			})

			// Add total serie
			let zones = []

			if (this.unfinished) {
				zones = [
					{value: this.labels.length - 2},
					{
						dashStyle: 'dot',
						fillColor: 'transparent'
					}
				]
			}

			// Calculate average visits for the period
			this.averageVisits = Math.ceil(dataRows.reduce((accumulator, currentValue) => {
				return accumulator + currentValue
			}, 0) / dataRows.length)

			this.$emit('totalAxisCalculated', {yAxisMax: Math.max(...dataRows), id: this.id})

			this.series.total = {
				name: 'total',
				type: 'line',
				marker: this.marker,
				data: dataRows,
				zoneAxis: 'x',
				zones: zones
			}
		},

		createSerie(source) {
			let zones = []

			if (this.unfinished) {
				zones = [
					{value: this.labels.length - 2},
					{
						dashStyle: 'dot',
						fillColor: 'transparent'
					}
				]
			}

			this.series[source] = {
				name: source,
				data: [],
				marker: this.marker,
				zoneAxis: 'x',
				zones: zones
			}
		},

		getColor(object) {
			const sources = this.sources

			for (let i = 0; i < sources.length; i++) {
				const organizationSourceKey = `colors.trafficSources.${object.name}`

				if (organizationSourceKey !== $org(organizationSourceKey)) {
					return $org(organizationSourceKey)
				}

				if (sources[i].slug !== object.name) {
					continue
				}

				return '#' + sources[i].color
			}

			switch (object.name) {
				case 'total':
					return '#d11e48'

				default:
					return '#91e8e1'
			}
		},

		render() {
			const el = this.$el.querySelector('.chart')

			let series = Object.keys(this.series)
				.map(key => {
					return this.series[key]
				})
				.sort((a, b) => {
					const labelA = a.name.toLowerCase()
					const labelB = b.name.toLowerCase()

					if (labelA === 'total') {
						return 1000
					}

					if (labelB === 'total') {
						return -1000
					}

					if (labelA < labelB) {
						return -1
					}

					if (labelA > labelB) {
						return 1
					}

					return 0
				})
				.map(serie => {
					serie.color = this.getColor(serie)
					return serie
				})

			let that = this

			this.chart = new HighCharts.Chart(el, {
				credits: false,
				chart: {
					type: 'area',
					stacking: 'normal',
					zoomType: 'x',
					height: 300
				},
				title: {
					text: null
				},
				legend: {
					enabled: false
				},
				tooltip: {
					useHTML: true,
					shared: true,
					formatter: function () {
						const options = {
							x: this.x,
							total: 0,
							points: this.points,
							that: that
						}

						return that.tooltipFormatter(options)
					}
				},
				plotOptions: {
					area: {
						stacking: 'normal'
					},
					series: {
						point: {
							events: {
								mouseOver: (event) => {
									eventHub.$emit('compare.analytics.visitors', {event})
								},
								mouseOut: (event) => {
									eventHub.$emit('compare.analytics.mouseOut')
								}
							}
						},
						marker: {
							enabled: true
						},
						events: {
							legendItemClick: function (event) {
								that.setVisibility(event)
							}
						}
					}
				},
				xAxis: {
					crosshair: true,
					categories: this.labels
				},
				yAxis: {
					title: {
						text: null
					},
					max: this.yAxisMaxValue ? this.yAxisMaxValue : null,
					plotLines: [{
						color: '#000',
						dashStyle: 'dash',
						value: that.averageVisits,
						width: '1',
						zIndex: 2
					}]
				},
				series: series
			})

			this.updateVisibility()
		},

		translate(name) {
			if (name === 'total') {
				return $t('total')
			}

			const key = `trafficSources.${name}`
			const translation = $t(key)

			if (key === translation) {
				const source = this.sources.find(source => {
					return source.slug === name
				})

				if (source && source.title) {
					return source.title
				}

				return name
			}

			return translation
		},

		updateVisibility() {
			if (!this.chart) {
				return
			}

			const chart = this.chart
			const series = chart.series

			Object.keys(series).forEach(key => {
				const serie = series[key]
				const serieName = serie.name

				if (!this.visible.includes(serieName)) {
					serie.hide()
					return
				}

				serie.show()
			})
		},

		setVisibility(event) {
			const chart = this.chart
			const series = chart.series

			let visible = []

			for (let key in series) {
				let serie = series[key]
				let serieName = serie.name

				if (!serie.visible) {
					continue
				}

				visible.push(serieName)
			}

			const target = event.target

			if (!target.visible) {
				visible.push(target.name)
			} else {
				visible.splice(visible.indexOf(target.name), 1)
			}
		},

		calculateChange(point) {
			const xIndex = point.point.x

			if (!this.comparisonData[point.series.name]) {
				return '<span></span>'
			}

			const latestValue = this.comparisonData[point.series.name].data[xIndex]
			const comparisonValue = point.y
			const decreasedValue = comparisonValue - latestValue

			if (!this.identity.language) {
				return '<span></span>'
			}

			const {code} = this.identity.language

			if (!code) {
				return '<span></span>'
			}

			const formattedLanguageCode = code.replace('_', '-')
			const difference = new Intl.NumberFormat(formattedLanguageCode).format(Math.floor((decreasedValue / latestValue) * 100))

			const color = difference < 0
				? '#9b5353'
				: '#6baa6b'

			return `<span style="color: ${color};">${difference}%</span>`
		},

		tooltipFormatter(options) {
			const {total, x, points, that} = options

			let tooltip = '<table width=\'180\' style=\'font-size: 13px;\'><thead><tr><th style=\'padding-bottom: 5px;\'><strong>' + x + '</strong><th></tr></thead><tbody>'

			points.forEach(point => {
				tooltip += '<tr>'
				tooltip += '<td width=\'60%\' style=\'padding-bottom: 3px;\'>' + this.translate(point.series.name) + ':</td>'
				tooltip += '<td width=\'20%\' style=\'padding-bottom: 3px; text-align: right;\'>' + point.y + '</td>'

				if (this.modeToggleChange) {
					tooltip += '<td width=\'20%\' style=\'padding-bottom: 3px; padding-left: 20px; text-align: right;\'>' + this.calculateChange(point) + '</td>'
				}

				tooltip += '</tr>'
			})

			tooltip += '</tbody></table>'

			return tooltip
		},

		mouseOut() {
			if (!this.hasDataWithinPeriod || !this.hasLoaded) {
				return
			}

			const chart = this.chart
			const series = chart.series

			series.forEach(serie => {
				serie.points.forEach(point => {
					point.setState('')
				})
			})

			chart.xAxis[0].hideCrosshair()
			chart.tooltip.hide()
		},

		mouseOverChart(payload) {
			if (!this.hasDataWithinPeriod || !this.hasLoaded) {
				return
			}

			const chart = this.chart
			const series = chart.series
			const event = payload.event

			if (!series.length) {
				return
			}

			const x = event.target.x

			const points = series
				.filter(serie => serie.visible)
				.map(serie => serie.points.length ? serie.points[x] : null)
				.filter(point => point !== null)

			if (!points.length) {
				return
			}

			chart.tooltip.refresh(points)
			chart.xAxis[0].drawCrosshair(null, points[0])
		}
	},

	components: {
		LogoLoader,
		Range
	}
}
</script>
