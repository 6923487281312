<template>
    <div class="interval" :class="{'full-line': fullLine}">
        <div class="btn-group visible-lg">
            <button v-for="(label, interval) in intervals"
                    class="btn"
                    :class="{'btn-primary': isSelected(interval)}"
                    @click="select(interval)">
                {{label}}
            </button>
        </div>

        <select class="form-control hidden-lg" v-model="selectedInterval">
            <option v-for="(label, interval) in intervals" v-bind:value="interval">
                {{label}}
            </option>
        </select>

        <div class="clearfix" v-if="fullLine"></div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    @media screen and (max-width: 1469px) {
        .visible-lg {
            display: none !important;
        }

        .hidden-lg {
            display: block !important;
        }
    }

    button {
        padding-top: 11px;
        border: none;

        &.btn-primary {
            color: #000;
        }
    }

    .interval {
        float: right;

        &.full-line {
            float: none;
            clear: both;
            display: block;
            margin-bottom: 10px;

            .btn-group {
                float: right;
            }
        }
    }
</style>

<script>
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  export default {
    mixins: [datepickerMixin],

    props: {
      fullLine: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        intervals: {
          day: $t('date.day'),
          week: $t('date.week'),
          month: $t('date.month'),
          year: $t('date.year')
        },
        selectedInterval: 'day'
      }
    },

    watch: {
      selectedInterval () {
        this.setInterval({ interval: this.selectedInterval })
      }
    },

    methods: {

      select (interval) {
        this.selectedInterval = interval

        this.setInterval({
          interval: interval
        })
      },

      isSelected (interval) {
        return this.interval === interval
      }
    }
  }
</script>
