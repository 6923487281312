<template>
    <div class="card-compare" v-tooltip="$t('visitors.compare.compareToggleTooltip')">
        <i class="fa" :class="[toggled ? 'fa-eye' : 'fa-low-vision']" @click="toggle"/>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    .card-compare {
        height: 40px;
        width: 40px;
        float: right;
        position: relative;

        i {
            cursor: pointer;
            font-size: 18px;
            @include absoluteCenter(50%, 50%);
        }
    }
</style>

<script>
  export default {
    data () {
      return {
        toggled: false
      }
    },

    methods: {
      toggle () {
        this.toggled = !this.toggled
        this.$emit('toggled', this.toggled)
      }
    }
  }
</script>
